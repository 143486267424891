/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { kebabCase } from 'lodash'
import { RiTimeLine, RiUserLine, RiPriceTag3Line } from "react-icons/ri"
import { IconContext } from "react-icons";

const PostTemplate = ({ data }) => {
  const { title, date, author, tags, image } = data.mdx.frontmatter
  const { body } = data.mdx
  const img = getImage(image.childImageSharp.gatsbyImageData)

  return (
    <Layout>
      <article sx={themeStyles.postContent} >
        <Link className="btn" to="/">
          Back to all posts
        </Link>
        <div sx={themeStyles.postContent.headerPage}>
          <h1>{title}</h1>
          <h6>
          <IconContext.Provider value={{ color: "text", size: 12, className: "global-class-name" }}>
            <span><RiUserLine /> {author}</span> | <span><RiTimeLine /> {date}</span>
          </ IconContext.Provider>  
          </h6>
        </div>
        <GatsbyImage image={img} alt="Blog Post" className="page-cover-image"/>
        <div>
          <MDXRenderer>{body}</MDXRenderer>
        </div>
        <div>
          {tags && tags.map((tag) => (
              <span key={tag}>
                <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                  <RiPriceTag3Line /> {tag}
                </Link>&nbsp;
              </span>
            ))}
          </div>
        <h4>
        <IconContext.Provider value={{ color: "text", size: 12, className: "global-class-name" }}>
            <span><RiUserLine /> {author}</span> | <span><RiTimeLine /> {date}</span>
        </ IconContext.Provider>
        </h4>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query getPost($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        date(formatString: "MMMM Do, YYYY")
        author
        tags
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`

export default PostTemplate


const themeStyles = {
  postContent: {
    headerPage: {
      textAlign: "center",
      margin: "10px 0",
      ".page-title": {
        fontWeight: "400",
        margin: "0 0 10px",
        lineHeight: "1.3"
      },
      ".page-date": {
        color: "text",
        fontWeight: "300",
        fontSize: "12px",
        textTransform: "uppercase",
      }
    },    
    ".page-cover-image": {
      position: "relative",
      padding: "0",
      margin: "0",
      maxHeight: "500px",
      backgroundColor: "background",
      overflow: "hidden",
      figure: {
        position: "relative",
        margin: "0",
      },
      figcaption: {
        position: "absolute",
        top: "20px",
        right: "20px",
        paddingLeft: "20px",
        color: "rgba(255, 255, 255, 0.7)",
        textShadow: "0 1px 5px rgba(0, 0, 0, 0.2)",
        fontWeight: "700",
        textTransform: "uppercase",
        fontSize: "10px",
      },
      ".page-image": {
        width: "100%",
        height: "100%",
      }
    },
    
    ".page-footer": {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      paddingBottom: "30px",
      margin: "30px 0",
      borderBottom: "1px solid $dark-gray",
      ".page-share": {
        marginBottom: "10px",
        textAlign: "left",
        a: {
          display: "inline-block",
          textDecoration: "none",
          backgroundColor: "background",
          border: "1px solid primary",
          padding: "5px 10px",
          marginLeft: "5px",
          color: "text",
          fontSize: "12px",
          transition: "background .3s",
          "&:firstChild": {
            marginLeft: "0",
          },
          "&:hover": {
            backgroundColor: "background",
          }
        }
      },
      ".page-tag": {
        span: {
          textDecoration: "none",
          fontSize: "10px",
          padding: "5px 10px",
          marginLeft: "5px",
          color: "text",
          textTransform: "uppercase",
          border: "1px solid text",
        }
      }
    }
  },

}